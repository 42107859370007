import moment from 'moment'
export default {
    data(){
        return {
            moment,
            pageSize:10,
            pageSizes:[10, 20, 30, 40],
            selectList:[],
            pageNum:1,
            total:0,
            tableLoading:false
        }
    },
    methods:{
        commonFormatter(row, column, cellValue, index){
            if(cellValue || cellValue === 0){
                return cellValue;
            } else {
                return '-';
            }
        },
        dateCommonFormatter(row, column, cellValue, index){
            if(cellValue || cellValue === 0){
                return this.moment(cellValue).format('YYYY-MM-DD HH:mm:ss');
            } else {
                return '-';
            }
        },
        numberFormatter(row, column, cellValue, index){
            if(cellValue || cellValue === 0) return Number(cellValue).toFixed(2)
            else return '-'
        },
        handleSizeChange(val) {
            this.pageNum = 1;
            this.pageSize = val;
            this.getTableData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getTableData();
        },
        handleSelectionChange(val) {
            this.selectList = val;
        },
        search(){
            this.pageNum = 1;
            this.getTableData();
        },
    }

}
